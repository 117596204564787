import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getchSomeRepas } from '../src/services/routeApi'; // Assurez-vous que le chemin d'importation est correct

export const fetchRepas = createAsyncThunk(
  'repas/fetchRepas',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getchSomeRepas();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const repasSlice = createSlice({
  name: 'repas',
  initialState: {
    data: [],
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRepas.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRepas.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchRepas.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { reducer } = repasSlice;
import 'keen-slider/keen-slider.min.css';
import FoodItem from './FoodItem';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from '@heroicons/react/24/outline';
import { fetchMenusRapide } from '../../redux/menusRapideSlice';

const FoodList = () => {
  const dispatch = useDispatch();
  const [currentSlide, setCurrentSlide] = useState(0);
  const menuRapide = useSelector(state => state.menusRapide.data);

  useEffect(() => {
    dispatch(fetchMenusRapide());
  }, [dispatch]);

  const [sliderRef, instanceRef] = useKeenSlider({
    mode: 'free-snap',
    renderMode: 'performance',
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    initial: 0,
    slides: { perView: 8, spacing: 10 },
    breakpoints: {
      '(max-width: 480px)': {
        slides: { perView: 4, spacing: 10 },
      },
      '(min-width: 480px)': {
        slides: { perView: 6, spacing: 10 },
      },
      '(min-width: 768px)': {
        slides: { perView: 8, spacing: 10 },
      },
    },
  });

  return (
    <div className='container-max my-6 mt-8'>
      <div className='flex items-center justify-between'>
        <h1 className='mb-4 font-bold text-2xl text-zinc-700'>
          What&apos;s on your mind?
        </h1>

        {instanceRef.current && (
          <div className='flex gap-2 items-center'>
            <button
              disabled={currentSlide === 0}
              onClick={() => instanceRef.current?.prev()}
              className='bg-gray-100 p-2 rounded-full disabled:text-gray-300'
            >
              <ArrowLongLeftIcon className='w-4 h-4' />
            </button>
            <button
              disabled={
                currentSlide ===
                instanceRef?.current?.track?.details?.slides?.length - 1
              }
              onClick={() => instanceRef.current?.next()}
              className='bg-gray-100 p-2 rounded-full disabled:text-gray-300'
            >
              <ArrowLongRightIcon className='w-4 h-4' />
            </button>
          </div>
        )}
      </div>

      <div ref={sliderRef} className='keen-slider'>
        {menuRapide && menuRapide.length > 0 ? (
          menuRapide.map((food) => (
            <FoodItem food={food} key={food.id} />
          ))
        ) : (
          <p>No quick menu found.</p>
        )}
      </div>
    </div>
  );
};

export default FoodList;

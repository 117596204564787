import React from 'react';

const FoodItem = ({ food }) => {
  return (
    <div className='keen-slider__slide flex flex-col items-center'>
      <img
        src={food?.image}
        className='w-18 h-18 rounded-lg object-cover mb-2'
        alt={food?.name || 'Food item'}
      />
      <p className='text-sm text-center'>
        {food?.name && (food.name.length > 10 ? `${food.name.substring(0, 10)}...` : food.name)}
      </p>
    </div>
  );
};

export default FoodItem;

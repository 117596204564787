import { useSelector, useDispatch } from 'react-redux';
import {
  decreaseItemQuantity,
  increaseItemQuantity,
  removeFromCart,
  selectItemsInCart,
} from '../../redux/cartSlice';
import { TrashIcon } from '@heroicons/react/24/solid';

const CartItemList = () => {
  const cartItems = useSelector(selectItemsInCart);
  const dispatch = useDispatch();

  const removeItem = (id) => dispatch(removeFromCart(id));
  const decreaseQuantity = (id) => dispatch(decreaseItemQuantity(id));
  const increaseQuantity = (id) => dispatch(increaseItemQuantity(id));

  if (cartItems.length === 0) {
    return (
      <div className='flex grow min-h-[60vh] justify-center items-center'>
        <p>Your cart is empty!</p>
      </div>
    );
  }

  return (
    <ul className='basis-7/12'>
      {cartItems.map((item) => (
        <li
          key={item.id}
          className='flex gap-4 justify-between max-w-[600px] my-4'
        >
          {/* Image Section */}
          <div className='basis-3/12'>
            <img
              className='w-full h-full md:h-auto object-cover block rounded-md aspect-square'
              src={item.image} 
              alt={item.name} 
            />
          </div>

          {/* Item Details */}
          <div className='basis-9/12'>
            <p className='text-lg font-semibold'>{item.name}</p>
            <p className='hidden md:block'>
              {item.description?.length > 50
                ? item.description.slice(0, 50) + '...'
                : item.description}
            </p>

            <p className='my-2 space-x-1'>
              <span className='font-semibold'>
                {(item.quantity * item.prix).toFixed(2)} Frs
              </span>
              <span className='text-gray-800 font-normal'>
                ({item.prix} × {item.quantity})
              </span>
            </p>

            {/* Quantity and Remove Actions */}
            <div className='flex justify-between items-center mt-2'>
              <div className='flex items-center'>
                <button
                  onClick={() => decreaseQuantity(item.id)}
                  disabled={item.quantity === 1}
                  className='bg-green-800 disabled:bg-green-800/50 disabled:cursor-not-allowed text-white font-bold w-8 h-8 rounded-md'
                >
                  -
                </button>
                <p className='font-bold w-8 h-8 flex justify-center items-center'>
                  {item.quantity}
                </p>
                <button
                  onClick={() => increaseQuantity(item.id)}
                  className='bg-green-800 text-white font-bold w-8 h-8 rounded-md'
                >
                  +
                </button>
              </div>

              <button
                onClick={() => removeItem(item.id)}
                className='border border-rose-400 text-xs font-semibold text-rose-700 p-2 px-1 rounded-md'
              >
                <TrashIcon className='w-6 h-6 text-rose-800' />
                
              </button>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default CartItemList;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchSomeUser, fetchSomePhone } from '../src/services/routeApi';


// Inscription d'un utilisateur (avec username, phone, et image)
export const signUpUser = createAsyncThunk(
  'auth/signUpUser',
  async ({ username, phone, image }, { rejectWithValue }) => {
    try {
      const response = await fetchSomePhone({ username, phone, image });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

// Récupérer la liste des utilisateurs
export const fetchUsers = createAsyncThunk(
  'auth/fetchUsers',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchSomeUser();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    users: [],
    isLoading: false,
    isAuthenticated: false,
    error: null,
    redirectTo: null, // Nouvel état pour la redirection
  },
  reducers: {
    logout: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      state.redirectTo = null; // Réinitialiser la redirection lors de la déconnexion
    },
    updateUserProfile: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
    setRedirectTo: (state, action) => {
      state.redirectTo = action.payload;
    },
    clearRedirectTo: (state) => {
      state.redirectTo = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Inscription utilisateur
      .addCase(signUpUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(signUpUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
        state.isAuthenticated = true;
        state.error = null;
      })
      .addCase(signUpUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.isAuthenticated = false;
      })
      // Récupération des utilisateurs
      .addCase(fetchUsers.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.users = action.payload;
        state.error = null;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { logout, updateUserProfile, setRedirectTo, clearRedirectTo } = authSlice.actions;
export default authSlice.reducer;

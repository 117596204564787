
import axios from 'axios';


const apiService = axios.create({
  baseURL: 'https://api.kourcier.com',

  
  headers: {
    'Content-Type': 'application/json', 
  },
  responseType: 'json',
  withCredentials: true,
});


export default apiService;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import apiService from '../src/services/Api';


export const getCommandesAsync = createAsyncThunk(
  'commande/fetchCommandes',
  async (_, { rejectWithValue }) => {
    try {
      // Appel API pour récupérer la liste des commandes
      const response = await apiService.get('/commandes');
      return response.data; // On retourne les données des commandes
    } catch (error) {
      // Gestion des erreurs avec rejectWithValue
      console.error("Erreur lors de la récupération des commandes:", error.response?.data);
      return rejectWithValue(error.response?.data || { message: "Impossible de récupérer les commandes" });
    }
  }
);

export const createCommandeAsync = createAsyncThunk(
    'commande/addSomeCommande',
    async (commandeData, { rejectWithValue }) => {
      try {
        console.log("Données envoyées à l'API:", commandeData);
        const response = await apiService.post('/commandes', commandeData, {
          headers: {
            'Content-Type': 'application/json',  
          },
        });
        return response.data;
      } catch (error) {
        console.error("Erreur lors de l'appel API:", error.response?.data);
        return rejectWithValue(error.response?.data || { message: "Une erreur est survenue" });
      }
    }
  );

  const commandeSlice = createSlice({
    name: 'commande',
    initialState: {
      currentCommande: null,
      commandes: [], // On ajoute un état pour stocker les commandes
      status: 'idle',
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        // Gestion de la création de commande
        .addCase(createCommandeAsync.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(createCommandeAsync.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.currentCommande = action.payload;
        })
        .addCase(createCommandeAsync.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.payload?.message || "Une erreur est survenue";
        })
  
        // Gestion de la récupération des commandes
        .addCase(getCommandesAsync.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(getCommandesAsync.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.commandes = action.payload; // Mettre à jour la liste des commandes
        })
        .addCase(getCommandesAsync.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.payload?.message || "Impossible de récupérer les commandes";
        });
    },
  });
  
  export const { resetCommandeState } = commandeSlice.actions;
  export default commandeSlice.reducer;
// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// export const saveCartToServer = createAsyncThunk(
//   'cart/saveToServer',
//   async (cartItems, { rejectWithValue }) => {
//     try {
//       const response = await new Promise(resolve =>
//         setTimeout(() => resolve({ data: cartItems }), 1000)
//       );
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// const cartSlice = createSlice({
//   name: 'cart',
//   initialState: {
//     items: [],
//     status: 'idle',
//     error: null
//   },
//   reducers: {
//     addToCart: (state, action) => {
//       const existingItem = state.items.find(item => item.id === action.payload.id);
//       if (existingItem) {
//         existingItem.quantity += 1;
//         existingItem.prix += existingItem.perPrice; // Ajoutez cette ligne
//       } else {
//         state.items.push({ ...action.payload, quantity: 1, perPrice: action.payload.prix });
//       }
//     },
//     updateCartItemQuantity: (state, action) => {
//       const { id, quantity } = action.payload;
//       const item = state.items.find(item => item.id === id);
//       if (item) {
//         const quantityDiff = quantity - item.quantity;
//         item.quantity = quantity;
//         item.prix += quantityDiff * item.perPrice; // Mise à jour du prix
//       }
//     },
//     removeFromCart: (state, action) => {
//       state.items = state.items.filter(item => item.id !== action.payload);
//     },
//     clearCart: (state) => {
//       state.items = [];
//     }
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(saveCartToServer.pending, (state) => {
//         state.status = 'loading';
//       })
//       .addCase(saveCartToServer.fulfilled, (state, action) => {
//         state.status = 'succeeded';
//       })
//       .addCase(saveCartToServer.rejected, (state, action) => {
//         state.status = 'failed';
//         state.error = action.payload;
//       });
//   },
// });

// export const { addToCart, updateCartItemQuantity, removeFromCart, clearCart } = cartSlice.actions;
// export default cartSlice.reducer;

// cartSlice.js



// import { createSlice } from '@reduxjs/toolkit';

// const cartSlice = createSlice({
//   name: 'cart',
//   initialState: {
//     items: [],
//   },
//   reducers: {
//     addToCart: (state, action) => {
//       const existingItem = state.items.find(item => item.id === action.payload.id);
//       if (existingItem) {
//         existingItem.quantity += 1;
//       } else {
//         state.items.push({ ...action.payload, quantity: 1 });
//       }
//     },
//     // autres reducers (removeFromCart, clearCart, etc.)
//   },
// });

// export const { addToCart } = cartSlice.actions;
// export default cartSlice.reducer;




// import { createSlice } from '@reduxjs/toolkit';

// const cartSlice = createSlice({
//   name: 'cart',
//   initialState: {
//     items: [],
//   },
//   reducers: {
//     addToCart: (state, action) => {
//       const existingItem = state.items.find((item) => item.id === action.payload.id);
//       if (existingItem) {
//         existingItem.quantity += 1;
//       } else {
//         state.items.push({ ...action.payload, quantity: 1 });
//       }
//     },
//     increaseItemQuantity: (state, action) => {
//       const item = state.items.find((item) => item.id === action.payload);
//       if (item) item.quantity += 1;
//     },
//     decreaseItemQuantity: (state, action) => {
//       const item = state.items.find((item) => item.id === action.payload);
//       if (item && item.quantity > 1) item.quantity -= 1;
//     },
//     removeFromCart: (state, action) => {
//       state.items = state.items.filter((item) => item.id !== action.payload);
//     },
//     clearCart: (state) => {
//       state.items = [];
//     },
//   },
// });

// export const { addToCart, increaseItemQuantity, decreaseItemQuantity, removeFromCart, clearCart } = cartSlice.actions;
// export const selectItemsInCart = (state) => state.cart.items;

// export default cartSlice.reducer;



import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: [],
  },
  reducers: {
    addToCart: (state, action) => {
      const existingItem = state.items.find((item) => item.id === action.payload.id);
      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        state.items.push({ ...action.payload, quantity: 1 });
      }
    },
    increaseItemQuantity: (state, action) => {
      const item = state.items.find((item) => item.id === action.payload);
      if (item) item.quantity += 1;
    },
    decreaseItemQuantity: (state, action) => {
      const item = state.items.find((item) => item.id === action.payload);
      if (item && item.quantity > 1) item.quantity -= 1;
    },
    removeFromCart: (state, action) => {
      state.items = state.items.filter((item) => item.id !== action.payload);
    },
    clearCart: (state) => {
      state.items = [];
    },
  },
});

export const { addToCart, increaseItemQuantity, decreaseItemQuantity, removeFromCart, clearCart } = cartSlice.actions;
export const selectItemsInCart = (state) => state.cart.items;

// Selector to calculate the total price of items in the cart
export const selectTotalPrice = (state) =>
  state.cart.items.reduce((total, item) => total + item.prix * item.quantity, 0);

export default cartSlice.reducer;

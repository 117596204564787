import { configureStore } from '@reduxjs/toolkit';
import cartReducer from './cartSlice';
import commandeSlice from './commandeSlice';
import { reducer as menuReducer } from './menuSlice';
import { reducer as repasReducer } from './repasSlice';
import { restaurantReducer } from './restaurantSlice';
import { reducer as slideReducer } from './slideSlice';
import authSlice from './authSlice';
import colisSlice from './colisSlice';
import { reducer as categoriesSlice } from "./categoriesSlice";
import favoritesSlice from './favoritesSlice';
import { reducer as menusRapideSlice } from "./menusRapideSlice";
import searchSlice from "./searchSlice";
import {reducer as priceSlice} from "./priceSlice";
import { reducer as notificationsSlice } from "./notificationSlice";


export const store = configureStore({
  reducer: {
    auth: authSlice,
    slide: slideReducer,
    repas: repasReducer,
    restaurants: restaurantReducer,
    menus: menuReducer,
    cart: cartReducer,
    commande: commandeSlice,
    colis: colisSlice,
    categories: categoriesSlice,
    favorites: favoritesSlice,
    menusRapide: menusRapideSlice,
    search: searchSlice,
    price: priceSlice,
    notification: notificationsSlice
 },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});


import { StarIcon } from '@heroicons/react/24/solid';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchRepas } from '../../redux/repasSlice';
import { useLocation, useParams } from 'react-router-dom';
import { addToCart } from '../../redux/cartSlice'; // Importez addToCart depuis cartSlice
import toast from 'react-hot-toast';

const RestaurantInfo = () => {
  const dispatch = useDispatch();
  const { id, name, image, ratings, adresse } = useParams();
  const location = useLocation();

  // Récupération des plats du restaurant depuis le store Redux
  const dishes = useSelector((state) => state.repas.data);

  useEffect(() => {
    dispatch(fetchRepas());
  }, [dispatch]);

  const handleAddToCart = (dish) => {
    dispatch(addToCart(dish));
    toast.success('Plat ajouté au panier !');
  };

  return (
    <div className='container max-w-4xl mx-auto mt-8'>
      {/* Image principale du restaurant */}
      <div className='relative mb-6'>
        <img src={image} alt={name} className='w-full h-64 object-cover rounded-lg' />
        <div className='absolute inset-0 bg-black bg-opacity-50 flex items-end p-4 rounded-lg'>
          <div>
            <h2 className='text-2xl font-bold text-white'>{name}</h2>
            <p className='text-sm text-gray-300'>{adresse}</p>
          </div>
          <div className='ml-auto bg-white p-2 rounded-md'>
            <p className='flex items-center text-green-500 font-bold'>
              <StarIcon className='w-4 h-4 mr-1' /> {ratings}
            </p>
            <p className='text-xs text-gray-500'>{adresse}</p>
          </div>
        </div>
      </div>

      {/* Liste des plats */}
      <h3 className='text-xl font-semibold my-4'>Menu {name}</h3>
      <div className='grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4'>
        {dishes.length > 0 ? (
          dishes.map((dish, i) => (
            <div key={i} className='bg-white p-4 rounded-lg shadow-md'>
              <img
                src={dish.image}
                alt={dish.name}
                className='w-full h-24 object-cover rounded-md mb-2'
              />
              <p className='text-sm font-semibold'>{dish.name}</p>
              <p className='text-xs text-gray-500'>{dish.prix} FCFA</p>
              
              {/* Bouton Ajouter au panier */}
              <button
                onClick={() => handleAddToCart(dish)}
                className='mt-2 w-full bg-green-800 text-white font-semibold py-1 px-3 rounded-md hover:bg-green-800'
              >
                Ajouter au panier
              </button>
            </div>
          ))
        ) : (
          <p className='col-span-full text-center text-gray-500'>No dishes available.</p>
        )}
      </div>
    </div>
  );
};

export default RestaurantInfo;

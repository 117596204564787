import { createSlice } from '@reduxjs/toolkit';

// const initialState = {
//   address: localStorage.getItem('address')
//     ? JSON.parse(localStorage.getItem('address'))
//     : {
//         latitude: 4.0511,
//         longitude: 9.7679,
//         city: 'Douala',
//       },
// };
const initialState = {
  address: (() => {
    const savedAddress = localStorage.getItem('address');
    try {
      return savedAddress ? JSON.parse(savedAddress) : {
        latitude: 4.0511,
        longitude: 9.7679,
        city: 'Douala',
      };
    } catch (error) {
      console.error("Invalid JSON in localStorage for 'address':", error);
      return {
        latitude: 4.0511,
        longitude: 9.7679,
        city: 'Douala',
      };
    }
  })(),
};


const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    setAddress: (state, action) => {
      state.address = action.payload;

      localStorage.setItem('address', JSON.stringify(state.address));
    },
  },
});

export const selectAddress = ({ address }) => address;

export const { setAddress } = addressSlice.actions;

export default addressSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import apiService from '../src/services/Api';


export const createColisAsync = createAsyncThunk(
    'colis/addSomeColis',
    async (formData, { rejectWithValue }) => {
      try {
        console.log("Données envoyées à l'API:", formData);
        const response = await apiService.post('/colis', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });
        console.log("Reponse API:", response.data);
        return response.data;
      } catch (error) {
        console.error("Erreur lors de l'appel API:", error.response?.data);
        return rejectWithValue(error.response?.data || { message: "Une erreur est survenue" });
      }
    }
  );
  export const getColisAsync = createAsyncThunk(
    'colis/fetchColis',
    async (_, { rejectWithValue }) => {
      try {
        // Appel API pour récupérer les colis
        const response = await apiService.get('/colis');
        return response.data; // Retourne les données des colis
      } catch (error) {
        console.error("Erreur lors de la récupération des colis:", error.response?.data);
        return rejectWithValue(error.response?.data || { message: "Impossible de récupérer les colis" });
      }
    }
  );

const colisSlice = createSlice({
    name: 'colis',
    initialState: {
      currentColis: null,
      colisList: [],
      status: 'idle',
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(createColisAsync.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(createColisAsync.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.currentColis = action.payload;
        })
        .addCase(createColisAsync.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.payload?.message || "Une erreur est survenue";
        })
        // Gestion de la récupération des colis
      .addCase(getColisAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getColisAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.colisList = action.payload; // Mettre à jour la liste des colis
      })
      .addCase(getColisAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload?.message || "Impossible de récupérer les colis";
      });
    },
  });

export const { resetColisState } = colisSlice.actions;
export default colisSlice.reducer;
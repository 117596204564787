const Footer = () => {
  return (
    <div className='container-max text-center py-4 border-t'>
      Foody, made by{' '}
      <a
        href='https://dineshrout.netlify.app'
        target={'_blank'}
        className='text-green-800'
      >
        Dinesh Routes
      </a>
    </div>
  );
};
export default Footer;

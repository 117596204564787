import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getchSomePriceCommande, getchSomePriceColis } from '../src/services/routeApi'; // Assurez-vous que le chemin d'importation est correct

// Thunk pour récupérer les prix des commandes
export const fetchPriceCommandeData = createAsyncThunk(
  'prix/fetchPriceCommande',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getchSomePriceCommande();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk pour récupérer les prix des colis
export const fetchPriceColisData = createAsyncThunk(
  'prix/fetchPriceColis',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getchSomePriceColis();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Slice Redux pour gérer l'état des prix
const priceSlice = createSlice({
  name: 'prix',
  initialState: {
    dataCommande: [], 
    dataColis:[],     
    statusCommande: 'idle', 
    statusColis: 'idle',    
    errorCommande: null,    
    errorColis: null        
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Gestion des états pour la récupération des prix des commandes
      .addCase(fetchPriceCommandeData.pending, (state) => {
        state.statusCommande = 'loading';
      })
      .addCase(fetchPriceCommandeData.fulfilled, (state, action) => {
        state.statusCommande = 'succeeded';
        state.dataCommande = action.payload; 
      })
      .addCase(fetchPriceCommandeData.rejected, (state, action) => {
        state.statusCommande = 'failed';
        state.errorCommande = action.payload;
      })

      // Gestion des états pour la récupération des prix des colis
      .addCase(fetchPriceColisData.pending, (state) => {
        state.statusColis = 'loading';
      })
      .addCase(fetchPriceColisData.fulfilled, (state, action) => {
        state.statusColis = 'succeeded';
        state.dataColis = action.payload;
      })
      .addCase(fetchPriceColisData.rejected, (state, action) => {
        state.statusColis = 'failed';
        state.errorColis = action.payload;
      });
  },
});

// Export du reducer pour l'inclure dans le store Redux
export const { reducer } = priceSlice;


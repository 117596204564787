import { Link } from 'react-router-dom';
import RestaurantCard, { withTopRatedLabel } from './RestaurantCard';
import ShimmerCard from './ShimmerCard';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchRestaurantsData } from '../../redux/restaurantSlice';

const RestaurantList = () => {
  const dispatch = useDispatch();
  const RestaurantCardTopRated = withTopRatedLabel(RestaurantCard);

  const restaurants = useSelector((state) => state.restaurants.data);
  const isLoading = useSelector((state) => state.restaurants.isLoading);
  const error = useSelector((state) => state.restaurants.error);

  useEffect(() => {
    dispatch(fetchRestaurantsData());
  }, [dispatch]);

 console.log("RestaurantCardTopRated: ", RestaurantCardTopRated.length);
  console.log("restaurants: ", restaurants);

  if (error) {
    return <p>Une erreur est survenue : {error}</p>;
  }

  return (
    <div className='container-max'>
      <h1 className='my-4 mt-8 font-bold text-2xl text-zinc-700'>
        Restaurants near you
      </h1>

      <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-8'>
  
        {isLoading ? (
  Array.from({ length: 15 }).map((_, i) => <ShimmerCard key={i} />)
) : restaurants && restaurants.length !== 0 ? (
  restaurants.map((restaurant, i) => (
    <Link
              to={{
                pathname: `/restaurants/${restaurant.id}`,
                state: { restaurant }, // Passez les informations du restaurant ici
              }}
              className='hover:scale-95 transition ease-in-out duration-300 relative z-10'
              key={restaurant.id}
            >
      {restaurant.ratings >= 2 ? (
        <RestaurantCardTopRated restaurant={restaurant} />
      ) : (
        <RestaurantCard restaurant={restaurant} />
      )}
    </Link>
  ))
) : (
  <p>No restaurant found!</p>
)}

      </div>
    </div>
  );
};

export default RestaurantList;

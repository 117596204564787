import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getchSomeCategories } from '../src/services/routeApi'; // Assurez-vous que le chemin d'importation est correct

export const fetchGategoriesData = createAsyncThunk(
  'repas/fetchCategories',
  async (_, { rejectWithValue }) => {
    try {
       console.log('Fetching Gtegories...');
      const response = await getchSomeCategories();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const categoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    dataCategories: [], 
    statusCategories: 'idle', 
    errorGtegories: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGategoriesData.pending, (state) => {
        state.statusCategories = 'loading'; 
      })
      .addCase(fetchGategoriesData.fulfilled, (state, action) => {
        state.statusCategories = 'succeeded';
        state.dataCategories = action.payload; 
      })
      .addCase(fetchGategoriesData.rejected, (state, action) => {
        state.statusCategories = 'failed';
        state.errorGtegories = action.payload; 
      });
  },
});

export const { reducer } = categoriesSlice;
import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'slide',
  initialState: {
    // Votre état initial
  },
  reducers: {
    // Vos reducers
  },
});

export const { actions, reducer } = userSlice;
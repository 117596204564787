// import { createSlice, current } from '@reduxjs/toolkit';

// const initialState = {
//   items: JSON.parse(localStorage.getItem('cart')) || [],
// };

// const cartSlice = createSlice({
//   name: 'cart',
//   initialState,
//   reducers: {
//     addToCart: (state, action) => {
//       state.items.push({
//         item: action.payload,
//         quantity: 1,
//       });

//       localStorage.setItem('cart', JSON.stringify(state.items));
//     },

//     removeFromCart: (state, action) => {
//       state.items = state.items.filter(
//         (cartItem) => cartItem?.item?.card?.info?.id !== action.payload.id
//       );
//       localStorage.setItem('cart', JSON.stringify(state.items));
//     },

//     increaseItemQuantity: (state, action) => {
//       const { id } = action.payload;

//       const itemToIncrease = state.items.find(
//         (cartItem) => cartItem?.item?.card?.info?.id === id
//       );

//       if (itemToIncrease) {
//         itemToIncrease.quantity += 1;
//         localStorage.setItem('cart', JSON.stringify(state.items));
//       }
//     },
//     decreaseItemQuantity: (state, action) => {
//       const { id } = action.payload;
//       const itemToDecrease = state.items.find(
//         (cartItem) => cartItem?.item?.card?.info?.id === id
//       );

//       if (itemToDecrease && itemToDecrease.quantity > 1) {
//         itemToDecrease.quantity -= 1;
//         localStorage.setItem('cart', JSON.stringify(state.items));
//       }
//     },
//     clearCart: (state) => {
//       state.items = [];
//       localStorage.removeItem('cart');
//     },
//   },
// });

// export const selectItemsInCart = ({ cart }) => cart?.items;

// export const selectTotalPrice = ({ cart }) => {
//   return cart?.items.reduce((total, cartItem) => {
//     return total + cartItem.item.itemPrice * cartItem.quantity;
//   }, 0);
// };

// export const {
//   addToCart,
//   removeFromCart,
//   increaseItemQuantity,
//   decreaseItemQuantity,
//   clearCart,
// } = cartSlice.actions;

// export default cartSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',
  initialState: { items: [] },
  reducers: {
    addToCart: (state, action) => {
      const existingItem = state.items.find((item) => item.id === action.payload.id);
      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        state.items.push({ ...action.payload, quantity: 1 });
      }
    },
    increaseItemQuantity: (state, action) => {
      const item = state.items.find((item) => item.id === action.payload);
      if (item) item.quantity += 1;
    },
    decreaseItemQuantity: (state, action) => {
      const item = state.items.find((item) => item.id === action.payload);
      if (item && item.quantity > 1) item.quantity -= 1;
    },
    removeFromCart: (state, action) => {
      state.items = state.items.filter((item) => item.id !== action.payload);
    },
  },
});

export const { addToCart, increaseItemQuantity, decreaseItemQuantity, removeFromCart } = cartSlice.actions;
export const selectItemsInCart = (state) => state.cart.items;
export default cartSlice.reducer;

import { CDN_URL } from '../utils/constants';

const Banner = ({ banner }) => {
  return (
    <div className='keen-slider__slide'>
      <img className='block w-full rounded-xl' src={banner?.image} alt='' />
    </div>
  );
};

export default Banner;

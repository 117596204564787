import { useState } from 'react';

const Login = () => {
  const [isSignup, setIsSignup] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    phone: '',
    password: '',
    city: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const toggleForm = () => {
    setIsSignup(!isSignup);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isSignup) {
      // handle signup logic
      console.log("Signing up:", formData);
    } else {
      // handle login logic
      console.log("Logging in:", { phone: formData.phone, password: formData.password });
    }
  };

  return (
    <div className='container-max flex justify-center items-center min-h-screen bg-white'>
      <div className='bg-white shadow-lg rounded-lg p-8 w-full max-w-md'>
        <h2 className='text-2xl font-bold text-center text-zinc-700 mb-6'>
          {isSignup ? 'Create Account' : 'Login'}
        </h2>

        <form onSubmit={handleSubmit} className='space-y-4'>
          {isSignup && (
            <div>
              <label className='block text-sm font-semibold text-gray-700 mb-1'>Username</label>
              <input
                type='text'
                name='username'
                value={formData.username}
                onChange={handleInputChange}
                className='w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:border-blue-500'
                required
                placeholder='Enter your username'
              />
            </div>
          )}

          <div>
            <label className='block text-sm font-semibold text-gray-700 mb-1'>Phone Number</label>
            <input
              type='text'
              name='phone'
              value={formData.phone}
              onChange={handleInputChange}
              className='w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:border-blue-500'
              required
              placeholder='Enter your phone number'
            />
          </div>

          <div>
            <label className='block text-sm font-semibold text-gray-700 mb-1'>Password</label>
            <input
              type='password'
              name='password'
              value={formData.password}
              onChange={handleInputChange}
              className='w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:border-blue-500'
              required
              placeholder='Enter your password'
            />
          </div>

          {isSignup && (
            <div>
              <label className='block text-sm font-semibold text-gray-700 mb-1'>City</label>
              <input
                type='text'
                name='city'
                value={formData.city}
                onChange={handleInputChange}
                className='w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:border-blue-500'
                required
                placeholder='Enter your city'
              />
            </div>
          )}

          <button
            type='submit'
            className='w-full bg-green-800 hover:bg-green-800 text-white font-bold py-2 rounded-lg'
          >
            {isSignup ? 'Sign Up' : 'Log In'}
          </button>
        </form>

        <p className='text-center text-sm text-gray-600 mt-4'>
          {isSignup ? 'Already have an account?' : "Don't have an account?"}{' '}
          <button
            onClick={toggleForm}
            className='text-green-900 font-bold hover:underline'
          >
            {isSignup ? 'Log In' : 'Sign Up'}
          </button>
        </p>
      </div>
    </div>
  );
};

export default Login;
